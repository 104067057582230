import { createSelector } from 'reselect';

import { presentState } from './helper';

import { selectItemsMap, selectSectionsMap } from './menu';

const getSelected = presentState( state => state.menu_edit.selected );

const getReorderSections = presentState( state => state.menu_edit.reorderSections );

export const selectSelected = createSelector(

  getSelected,
  (selected) => selected
);

const getMapValue = (map, selectedObjWithId ) => selectedObjWithId && map.get( selectedObjWithId.id );

export const selectSelectedSection = createSelector(

  selectSelected,
  selectSectionsMap,
  (selected, sectionsMap) => getMapValue( sectionsMap, selected.section )
);

export const selectSelectedItem = createSelector(

  selectSelected,
  selectItemsMap,
  (selected, itemsMap) => getMapValue( itemsMap, selected.item )
);

export const selectSelectedValues = createSelector(

  getSelected,
  selectSelectedSection,
  selectSelectedItem,
  (selected, section, item ) => ({

    selected,
    section,
    item,
    header: selected?.header
  })
);

export const selectIsReorderSections = createSelector(

  getReorderSections,
  (enabled) => enabled === true
);

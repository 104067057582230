import { createSelector } from 'reselect';

const getMenus = state => state.menus.menus;

const getLoading = state => state.menus.loading;

export const selectMenus = createSelector(

  getMenus,
  (menus) => menus.map( ({id, logo,...menu}) => ({

    id,
    ...menu,
    logo: logo ? `https://menu.assets.candr.com/${id}/${logo}` : null,
  }))
);

export const selectIsLoading = createSelector(

  getLoading,
  (loading) => loading
);

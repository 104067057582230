import { handleActions } from './handle-actions';

import {
         MENU_EDIT_SET_SELECTED,
         MENU_EDIT_SECTION_REORDER,
       } from 'actions/menu_edit';

import {
         MENU_SECTION_ADD,
         MENU_SECTION_DELETE,

         MENU_ITEM_ADD,
         MENU_ITEM_DELETE,
       } from 'actions/menu';

const DEFAULT_STATE = {

  selected: {},
  reorderSections: false,
};

const reducer = handleActions( {

    [MENU_EDIT_SET_SELECTED]: (draft, { selected, replace } ) => {

      if( !replace && selected ) {

        selected = { ...draft.selected, ...selected };
      }

      draft.selected = selected || {}
    },

    [MENU_SECTION_ADD]: (draft, {id} ) => {

      draft.selected = { section: { id } };
    },

    [MENU_ITEM_ADD]: (draft, { values = {} } ) => {

      const { id } = values;

      if( id ) {

        draft.selected = { item: { id } };
      }
    },

    [MENU_ITEM_DELETE]: (draft, { id }) => {

      if( id === draft.itemId ) {

        draft.itemId = null;
      }
    },

    [MENU_SECTION_DELETE]: (draft, { id }) => {

      if( id === draft.sectionId ) {

        draft.sectionId = null;
        draft.itemId = null;
      }
    },

    [MENU_EDIT_SECTION_REORDER]: (draft, {enabled} ) => {

      draft.reorderSections = enabled === true;
    }
  },

  DEFAULT_STATE,
);

export default reducer;

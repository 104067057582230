import React, { createContext, useState, useEffect } from "react";

import { auth } from "./firebase";

export const UserContext = createContext({ user: null });

const UserProvider = ({children}) => {

  const [initializing, setInitializing] = useState( true );

  const [user,setUser] = useState( null );

  useEffect( () => {

    auth.onAuthStateChanged( async (userAuth) => {

      if( initializing ) {

        setInitializing( false );
      }

      //console.log( 'onAuthStateChanged', userAuth );
      setUser( userAuth );
    });
  },[]);

  if( initializing ) {

    return null;
  }

  return (

    <UserContext.Provider value={user}>
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;

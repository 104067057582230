import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';

import { enableAllPlugins } from 'immer';

import { configureStore } from './redux/store';

import { Grommet } from 'grommet';

import { BrowserRouter as Router } from "react-router-dom";

import UserProvider from './UserProvider';

import theme from './theme';
import './index.css';

enableAllPlugins();

const store = configureStore();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Grommet theme={theme} full>
          <UserProvider>
            <App/>
          </UserProvider>
        </Grommet>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { take, takeEvery, put, select } from 'redux-saga/effects';

import { PUBLISH_MENU_START, menuPublished } from 'actions/publish';

import { MENU_SAVED, saveMenu } from 'actions/menu';

import { selectSaveState } from 'selectors/save';

function* handlePublish( publishAction ) {

  try {

    const { saving } = yield select( selectSaveState );

    if( saving ) {

      yield put( menuPublished( null, 'save already in progress' ) );
      return;
    }

    yield put( saveMenu( false, { op: 'publish' } ) );

    const savedAction = yield take( MENU_SAVED );

    const { payload: { version, menuInfo, error } } = savedAction;

    if( !menuInfo ) {

      yield put( menuPublished( null, error ) );
      return;
    }

    yield put( menuPublished( { menuInfo, version } ) );
  }
  catch( err ) {

    console.log( err );
    yield put( menuPublished( null, err.message ) );
  }
}

export default function* publishSaga() {

  yield takeEvery( PUBLISH_MENU_START, handlePublish );
}

import React, { useEffect }  from 'react';

import MainContainer from './components/MainContainer';

import { Switch, Route, Redirect } from "react-router-dom";

import SignInPage from './pages/SignInPage';
import FinishSignInPage from './pages/FinishSignInPage';

import { useAuthenticatedUser } from 'lib/hooks';

import { useDispatch, useSelector } from 'react-redux';

import { selectIsInitialized } from 'selectors/user';

import { userLogin } from 'actions/user';


import MenuPage from './pages/MenuPage';
import MenusPage from './pages/MenusPage';

function App() {

  const user = useAuthenticatedUser();

  const dispatch = useDispatch();

  const isInitialized = useSelector( selectIsInitialized );

  useEffect( () => {

    if( !user ) {

      // wipe out data
      return;
    }

    dispatch( userLogin()  );

  },[user, dispatch ] )

  if( !user ) {

    return (
      <Switch>
        <Route path="/finishSignIn" component={FinishSignInPage}/>
        <Route path="*" component={SignInPage}/>
      </Switch>
    );
  };

  if( !isInitialized ) {

    return <MainContainer/>
  };

  return (
    <MainContainer>
      <Switch>
        <Route path="/menus/:id" component={MenuPage}/>
        <Route path="/menus" component={MenusPage}/>
        <Route path="*">
          <Redirect to="/menus"/>
        </Route>
      </Switch>
    </MainContainer>
  );
}

export default App;

import { handleActions } from './handle-actions';

import { MENU_SAVED, MENU_ADD, MENU_ADDED, MENU_LOADED, MENU_UPDATE_VALUES, MENU_UPDATE_LOGO } from 'actions/menu';

import { MENUS_LOAD, MENUS_LOADED } from 'actions/menus';

import { USER_LOGOUT} from 'actions/user';

const DEFAULT_STATE = {

  loading: false,
  menus: [],
  currentMenuId: null,
};

const updateMenu = ( menus, id, updater ) => {

  const index = menus.findIndex( (menu) => menu.id === id );

  if( index > -1 ) {

    updater( menus[ index ] );
  }
}

const reducer = handleActions( {

    [ USER_LOGOUT ]: ( draft ) => {

      draft.loading = false;
      draft.menus = [];
    },

    [ MENUS_LOAD ]: ( draft, ) => {

      draft.loading = true;
    },

    [ MENU_ADD ]: ( draft ) => {

      draft.loading = true;
    },

    [ MENU_ADDED ]: ( draft, { menu } ) => {

      draft.loading = false;

      if( menu ) {

        draft.menus.push( menu );
      }
    },

    [ MENUS_LOADED ]: ( draft, { menus } ) => {

      draft.menus = menus || [];
      draft.loading = false;
    },

    [ MENU_LOADED ]: ( draft, { id } ) => {

      draft.currentMenuId = id;
    },

    [ MENU_UPDATE_VALUES]: ( draft, { values } ) => {

      updateMenu( draft.menus, draft.currentMenuId, (menu) => {

        if( values.name ) {

          menu.name = values.name;
        }
      });
    },

    [ MENU_SAVED ]: (draft, { menuInfo } ) => {

      if( menuInfo ) {

        const index = draft.menus.findIndex( (menu) => menu.id === menuInfo.id );

        if( index > -1 ) {

          draft.menus[ index ] = { ...draft.menus[ index ], ...menuInfo };
        }
      }
    },

    [ MENU_UPDATE_LOGO ]: ( draft, { logo } ) => {

      updateMenu( draft.menus, draft.currentMenuId, (menu) => {

        menu.logo = logo;
      });
    },
  },
  DEFAULT_STATE,
);

export default reducer;

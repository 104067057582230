import React, { useEffect, useState } from "react";

import { Box, Text } from 'grommet';

import { useHistory } from "react-router-dom";

import Spinner from 'components/Spinner';

import { auth } from '../firebase';

const FinishSignInPage = () => {

  const [signInState, setSignInState] = useState( 'checking' );

  const history = useHistory();

  useEffect( () => {

        // Confirm the link is a sign-in with email link.
    if (auth.isSignInWithEmailLink(window.location.href)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.
      let email = window.localStorage.getItem('emailForSignIn');
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt('Please provide your email for confirmation');
      }
      // The client SDK will parse the code from the link for you.
      auth.signInWithEmailLink(email, window.location.href)
        .then(function(result) {
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user via result.user
          // Additional user info profile not available via:
          // result.additionalUserInfo.profile == null
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser

          //message.success( 'You are now signed in' );

          history.replace( '/' );
        })
        .catch(function(error) {
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
          //setMessage( error.message );
          //message.error( 'Failed to sign in' );
          setSignInState( 'failed' );
        });
    }
  }, [history]);

  return (
    <Box background="#14213d" fill align="center" justify="center">
      <Box>
        {
          signInState === 'checking' &&
          <Spinner color="white" size={48}/>
        }
        {
          signInState === 'failed' &&
          <Box pad="medium" background="white" border={{side: 'all', color: 'red', size: 'small'}} round="small">
            <Text>Failed to authenticate</Text>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default FinishSignInPage;

import React from 'react';

const CANDR_ORANGE = '#fca311';

const FONT_SIZES = {

  small: 18,
  medium: 28,
  large: 48,
};

const Logo = ({mode = 'dark', size = 'medium'}) => {

  const candrColor = mode === 'dark' ? 'white' : 'black';

  const fontSize = FONT_SIZES[ size ] || FONT_SIZES.medium;

  return (
    <span>
      <span style={{color: candrColor, fontSize: fontSize, fontWeight: 600}}>candr</span>
      <span style={{paddingLeft: 4}}>
        <span style={{color: CANDR_ORANGE, fontSize: fontSize, fontWeight: 800}}>MENU</span>
      </span>
    </span>
  );
};

export default Logo;

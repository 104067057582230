import React from 'react';

import { Box, Keyboard, TextInput } from 'grommet';

import Tag from './Tag';

const TagInput = ({ value = [], onAdd, onChange, onRemove, ...rest }) => {
  const [currentTag, setCurrentTag] = React.useState('');
  const [box, setBox] = React.useState();
  const boxRef = React.useCallback(setBox, []);

  const doSetCurrentTag = (value) => {

    setCurrentTag( value );

    onChange && onChange( value );
  };

  const updateCurrentTag = event => {
    const { target: { value } } = event;

    doSetCurrentTag( value );
  };

  const onAddTag = tag => {
    if (onAdd) {

      onAdd(tag);
      doSetCurrentTag('');
    }
  };

  const onEnter = () => {
    if (currentTag.length) {
      onAddTag(currentTag);
      doSetCurrentTag('');
    }
  };

  const renderValue = () =>
    value.map((v, index) => (
      <Tag
        margin="xxsmall"
        key={`${v}${index + 0}`}
        onRemove={() => onRemove(v)}
      >
        {v}
      </Tag>
    ));

  return (
    <Keyboard onEnter={onEnter}>
      <Box
        direction="row"
        align="center"
        pad={{ horizontal: 'xsmall' }}
        border="all"
        ref={boxRef}
        wrap
      >
        {value.length > 0 && renderValue()}
        <Box flex style={{ minWidth: '120px' }}>
          <TextInput
            type="search"
            plain
            dropTarget={box}
            {...rest}
            onChange={updateCurrentTag}
            value={currentTag}
            onSelect={event => {
              event.stopPropagation();
              onAddTag(event.suggestion);
            }}
          />
        </Box>
      </Box>
    </Keyboard>
  );
};

const TagTextInput = ({placeholder = '', tags = [], allTags = [], onUpdate = () => {} }) => {

  const [suggestions, setSuggestions] = React.useState( allTags );

  const onRemoveTag = (tag) => onUpdate( tags.filter( (t) => t !== tag ) );

  const onAddTag = (tag) => onUpdate( [...new Set( [...tags, tag] ).values() ] );

  const onFilterSuggestion = (value) => {

    value = value.toLowerCase();

    return setSuggestions( allTags.filter( (tag) => tag.toLowerCase().indexOf(value) >= 0 ) );
  };

  return (
    <TagInput
      placeholder={placeholder}
      suggestions={suggestions}
      value={tags}
      onRemove={onRemoveTag}
      onAdd={onAddTag}
      onChange={(value) => onFilterSuggestion(value)}
    />
  );
};

export default TagTextInput;

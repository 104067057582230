import { combineReducers } from 'redux';

import undoable from 'redux-undo';

import user from './user';
import menus from './menus';
import save from './save';
import publish from './publish';

import menu from './menu';
import menu_edit from './menu_edit';

import filter from './filter';
import grouper from './grouper';

const current_menu = undoable(
  combineReducers({
      menu,
      menu_edit,
    }
  ),
  {
    groupBy: grouper,
    filter,
  }
);

const rootReducer = () => combineReducers( {

  current_menu,
  user,
  menus,
  save,
  publish,
});


export default rootReducer;

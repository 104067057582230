import React from 'react';

import { Box, Button, Text } from 'grommet';

import { FormClose } from 'grommet-icons';

const Tag = ({ children, onRemove, ...rest }) => {

  return (
    <Box
      direction="row"
      align="center"
      background="accent-4"
      pad={{ left: 'small', right: onRemove ? 'small' : 'small', vertical: 'xxsmall' }}
      margin={{ vertical: 'xxsmall' }}
      round="medium"
      {...rest}
    >
      <Text size="medium" margin={{ right: onRemove ? 'xsmall' : 'none' }}>
        {children}
      </Text>
      { onRemove &&
        <Button plain icon={<FormClose size="26px" color="dark-3"/>} onClick={() => onRemove()}/>
      }
    </Box>
  );
};

export default Tag;

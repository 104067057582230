import { createSelector } from 'reselect';

import { presentState, renderSection, renderItem } from './helper';

const getLoading = presentState( state => state.menu.loading );

const getId = presentState( state => state.menu.id );

const getName = presentState( state => state.menu.name );

const getLogo = presentState( state => state.menu.logo );

const getSectionsMap = presentState( state => state.menu.sections );

const getItemsMap = presentState( state => state.menu.items );

const getOptionsMap = presentState( state => state.menu.options );

const getMeta = presentState( state => state.menu.meta );

const getTheme = presentState( state => state.menu.theme );

export const selectIsLoading = createSelector(

  getLoading,
  (loading) => loading
);

export const selectTheme = createSelector(

  getTheme,
  (theme) => theme
);

export const selectMenuInfo = createSelector(

  getId,
  getName,
  getLogo,
  selectTheme,
  (id, name,logo, theme) => ({
    id,
    name,
    logo: id && logo ? `https://menu.assets.candr.com/${id}/${logo}` : null,
    theme,
  })
);

export const selectOptionsMap = createSelector(

  getOptionsMap,
  (optionsMap) => optionsMap
);

export const selectItemsMap = createSelector(

  getItemsMap,
  selectOptionsMap,
  (itemsMap, optionsMap) => new Map( [ ...itemsMap.entries() ].map( ([id,item], index ) => [id, renderItem( item, optionsMap )] ) )
);

export const selectSectionsMap = createSelector(

  getSectionsMap,
  selectItemsMap,
  (sectionsMap, optionsMap) => new Map( [...sectionsMap.entries() ].map( ([id,section]) => [id, renderSection( section, optionsMap )] ) )
);

export const selectSections = createSelector(

  selectSectionsMap,
  (sectionsMap) => [...sectionsMap.values() ]
);

export const selectAllTags = createSelector(

  getItemsMap,
  (itemsMap) => {

    // start with the usual suspects
    const tags = new Set( ['vegan', 'gluten-free', 'vegetarian', 'dairy-free', 'nut-free' ] );

    for( const item of itemsMap.values() ) {

      const { tags: itemTags = [] } = item;

      for( const tag of itemTags ) {

        tags.add( tag );
      }
    }

    return [...tags.values() ].sort();
  }
)

export const selectMenuForSave = createSelector(

  getId,
  getName,
  getLogo,
  getTheme,
  getOptionsMap,
  getSectionsMap,
  getItemsMap,
  getMeta,
  (id, name, logo, theme, optionsMap, sectionsMap, itemsMap, meta ) => ({

    id,
    name,
    logo,
    theme,
    ...meta,
    options: [ ...optionsMap.values() ],
    sections: [ ...sectionsMap.values() ].map( ({index,last,...section}) => section ),
    items: [ ...itemsMap.values() ].map( ({index,last,sectionId, ...item}) => item ),
  })
);

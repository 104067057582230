import React from 'react';

import { Box, Form, FormField, TextInput, TextArea } from 'grommet';

import { useDispatch, useSelector } from 'react-redux';

import { updateMenuItemValues } from 'actions/menu';
import { selectAllTags } from 'selectors/menu';

import { price as validatePrice } from 'lib/validators';

import TagTextInput from 'components/TagTextInput';

const MenuItemPane = ({item}) => {

  const dispatch = useDispatch();

  const allTags = useSelector( selectAllTags );

  const values = { name: item.name || '', desc: item.desc || '', price: item.price || '' };

  const setValues = (updated) => {

    const { name, desc, price } = updated;

    const updatedValues = { name, desc, price };

    dispatch( updateMenuItemValues( item.id, updatedValues ) );
  };

  return (

    <Box gap="small" pad={{top: "medium"}} fill>
      <Form
        value={values}
        onChange={(nextValue) => setValues( nextValue )}
        validate="blur"
      >
        <FormField name="name" label="Name" required={true}>
          <TextInput name="name"/>
        </FormField>
        <FormField name="desc" label="Description">
          <TextArea name="desc" fill={true} rows={3}/>
        </FormField>
        <Box basis="small" align="start">
          <FormField name="price" label="Price" validate={validatePrice}>
            <TextInput name="price" placeholder="price"/>
          </FormField>
        </Box>
      </Form>
      <Box>
        <TagTextInput
          placeholder="Add tags..."
          tags={item.tags || []}
          allTags={allTags}
          onUpdate={(tags) => dispatch( updateMenuItemValues( item.id, { tags }) )}
        />
      </Box>
    </Box>
  );
};

export default MenuItemPane;

import { handleActions } from './handle-actions';

import { createItemId, createValueFromInput } from 'lib/id';

import { MENU_LOAD, MENU_LOADED,

         MENU_UPDATE_VALUES, MENU_UPDATE_LOGO, MENU_UPDATE_THEME,

         // sections
         MENU_SECTION_ADD, MENU_SECTION_UPDATE_VALUES, MENU_SECTION_DELETE, MENU_SECTION_MOVE,

         // menu items
         MENU_ITEM_ADD, MENU_ITEM_UPDATE_VALUES, MENU_ITEM_DELETE, MENU_ITEM_MOVE,

         MENU_ITEM_OPTION_MOVE,

         // menu options
         MENU_OPTION_ADD, MENU_OPTION_UPDATE, MENU_OPTION_DELETE,

         // menu option values
         MENU_OPTION_VALUE_UPDATE, MENU_OPTION_VALUE_MOVE, MENU_OPTION_VALUE_DELETE,
       } from 'actions/menu';

import arrayMove from 'array-move';

import { themes } from '@candr-com/menu-common-ui';

const processSection = ( { items, ...section }, index, last ) => ({

  ...section,
  index,
  last,
  items,
});

const processItem = ({options, ...item} ) => ({

  ...item,
  options: options || [],
});

const fixupItems = (section, itemsMap) => {

  const lastIndex = Math.max( 0, section.items.length - 1 );

  section.items.forEach( (itemId, index ) => {

    const item = itemsMap.get( itemId );

    item.sectionId = section.id;
    item.index = index;
    item.last = (index === lastIndex );
  });
};

// const processOption = ({options,...option}) => ({
//
//   ...option,
//   options: new Map( options.map( (opt) => [opt.value, opt]))
// });

const DEFAULT_STATE = {

  loading: false,
  id: null,
  name: '',
  logo: null,
  sections: new Map(),
  items: new Map(),
  options: new Map(),
  theme: themes.simple,
  meta: {},
};

const updateMappedEntity = (map, id, updater) => {

  const obj = map.get( id );

  if( obj ) {

    const updated = updater( obj );

    if( updated && updated !== obj ) {

      map.set( id, updated );
    }
  }
};

const mapSections = (sections) => (

  new Map( sections.map( (section, index ) => [section.id, processSection( section, index, (index === (sections.length -1)) ) ] ) )
)

const updateItem = ( draft, id, updater ) => updateMappedEntity( draft.items, id, updater );

const updateOption = ( draft, id, updater ) => updateMappedEntity( draft.options, id, updater );

const updateSection = ( draft, id, updater ) => updateMappedEntity( draft.sections, id, updater );

const reducer = handleActions( {

    [ MENU_LOAD ]: ( draft, { id } ) => {

      draft.loading = true;
    },

    [ MENU_LOADED ]: ( draft, { id, data, error } ) => {

      const { id: _id, name = '', logo = null, sections = [], items = [], options = [], theme, ...meta } = data;

      draft.loading = false;
      draft.id = id;
      draft.name = name;
      draft.logo = logo;

      draft.theme = theme || themes.simple;

      draft.sections = mapSections( sections );
      draft.items = new Map( items.map( (item ) => [item.id, processItem( item) ] ) );

      for( const section of draft.sections.values() ) {

        fixupItems( section, draft.items );
      }

      draft.options = new Map( options.map( (option) => [option.id, option ] ) );
      draft.meta = meta;
    },

    [MENU_UPDATE_VALUES]: (draft, { values } ) => {

      const { name } = values;

      if( name !== undefined ) {

        draft.name = name;
      }
    },

    [MENU_UPDATE_LOGO]: (draft, { logo } ) => {

      draft.logo = logo;
    },

    [MENU_UPDATE_THEME]: (draft, { theme } ) => {

      draft.theme = theme;
    },

    [MENU_SECTION_ADD]: (draft, { id, values } ) => {

      const section = { id, ...values, items: [] };

      draft.sections.set( id, section );

      draft.sections = mapSections( [...draft.sections.values() ] );
    },

    [ MENU_SECTION_UPDATE_VALUES ]: (draft, { id, values }) => {

      updateSection( draft, id, (section) => {

        const { items, ...sectionValues } = section;

        return { ...sectionValues, ...values, id, items };
      });
    },

    [MENU_SECTION_DELETE]: (draft, { id }) => {

      const section = draft.sections.get( id );

      if( section ) {

        // kill all items
        section.items.forEach( (itemId) => {

          const item = draft.items.get( itemId );

          item.options.forEach( (optionId) => draft.options.delete( optionId ) );

          draft.items.delete( itemId );
        });

        draft.sections.delete( id );
      }
    },

    [MENU_SECTION_MOVE]: (draft, { from, to } ) => {

      draft.sections = mapSections( arrayMove( [ ...draft.sections.values() ], from, to ) );
    },

    [MENU_ITEM_ADD]: (draft, { sectionId, values = {} }) => {

      updateSection( draft, sectionId, (section) => {

        const { id = createItemId(), ...itemValues } = values;

        const item = processItem( { id, ...itemValues } );

        section.items.push( id );

        draft.items.set( id, item );

        fixupItems( section, draft.items );
      });
    },

    [MENU_ITEM_DELETE]: (draft, { id }) => {

      const item = draft.items.get( id );

      if( item ) {

        updateSection( draft, item.sectionId, (section) => {

          // remove options
          item.options.forEach( (optionId) => draft.options.delete( optionId ) );

          // remove from section
          section.items = section.items.filter( (itemId) => itemId !== id );
          fixupItems( section, draft.items );

          draft.items.delete( id );
        });
      }
    },

    [MENU_ITEM_MOVE]: (draft, { id, from, to, destId }) => {

      updateSection( draft, id, (section) => {

        if( !destId || (id === destId) ) {

          section.items = arrayMove( section.items, from, to );

          fixupItems( section, draft.items );

          return;
        }

        // move to another section
        updateSection( draft, destId, (destSection) => {

          const [item] = section.items.splice( from, 1 );
          fixupItems( section, draft.items );

          destSection.items.splice( to, 0, item );
          fixupItems( destSection, draft.items );
        })
      });
    },

    [ MENU_ITEM_UPDATE_VALUES ]: (draft, { id, values }) => {

      const menuItem = draft.items.get( id );

      if( menuItem ) {

        draft.items.set( id, { ...menuItem, ...values } );
      }
    },

    [ MENU_ITEM_OPTION_MOVE ]: (draft, { id, from, to } ) => {

      updateItem( draft, id, (item) => {

        item.options = arrayMove( item.options, from, to );
      });
    },

    [ MENU_OPTION_ADD ]: (draft, { id, values, itemId } ) => {

      const option = { ...values, id, options: [] };

      draft.options.set( id, option );

      updateItem( draft, itemId, (item) => {

        // add reference
        item.options.push( id );
      })
    },

    [ MENU_OPTION_UPDATE ]: (draft, { id, values } ) => {

      updateOption( draft, id, ({options, ...option }) => {

        return { ...option, ...values, options, id };
      });
    },

    [ MENU_OPTION_DELETE ]: (draft, { id } ) => {

      draft.options.delete( id );

      for( const item of draft.items.values() ) {

        const index = item.options.indexOf( id );

        if( index > -1 ) {

          item.options.splice( index, 1 );
        }
      }
    },

    [ MENU_OPTION_VALUE_UPDATE ]: (draft, { id, index, values } ) => {

      updateOption( draft, id, (option) => {

        if( values.name ) {

          values.value = createValueFromInput( values.name );
        }

        if( index === null ) {

          option.options.push( { ...values } );
        }
        else {

          option.options[ index ] = { ...option.options[index], ...values };
        }
      });
    },

    [ MENU_OPTION_VALUE_MOVE ]: (draft, { id, from, to }) => {

      updateOption( draft, id, (option) => {

        option.options = arrayMove( option.options, from, to );
      })
    },

    [ MENU_OPTION_VALUE_DELETE ]: (draft, { id, index }) => {

      updateOption( draft, id, (option) => {

        option.options.splice( index, 1 );
      });
    },
  },

  DEFAULT_STATE,
);

export default reducer;

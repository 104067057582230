import { createAction } from 'redux-actions';

export const USER_LOGIN = 'USER_LOGIN';

export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_LOGIN_COMPLETE = 'USER_LOGIN_COMPLETE';

export const userLogin = createAction( USER_LOGIN, (complete = false) => ({complete}) );

export const userLogout = createAction( USER_LOGOUT, () => ({}) );

export const userLoginComplete = createAction( USER_LOGIN_COMPLETE, () => ({}) );

import React, { useState } from "react";

import { Box, Button, Form, FormField, Text, TextInput } from 'grommet';
import { MailOption } from 'grommet-icons';

import Logo from 'components/Logo';

import { signInWithEmail } from '../firebase';

const SignInWithEmailPanel = ({onOk, onCancel}) => {

  const [values, setValues] = useState( { email: '' } );

  return (
    <Box background="white" round="small" pad="medium" gap="large">
      <Form
        value={values}
        onChange={nextValue => setValues(nextValue)}
        onSubmit={({ value: { email }} ) => onOk( email )}
      >
        <Box gap="medium" >
          <FormField name="email" label="">
            <TextInput size="medium" name="email" placeholder="email address"/>
          </FormField>
          <Box direction="row" gap="small" align="end">
            <Button secondary onClick={onCancel} label="Cancel"/>
            <Button primary type="submit" label="Continue"/>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

const SignIn = () => {

  const [showOp, setShowOp] = useState( null );

  return (
    <Box fill background="#14213d" align="center" justify="center">
      <Box align="center">
        <Box margin="medium">
          <Logo mode="dark" size="large"/>
        </Box>
        {
          !showOp &&
          <Box>
            <Button
              primary
              color="brand"
              onClick={() => setShowOp( { op: 'signin_email' } )}
              icon={<MailOption/>}
              label="Sign in with email"
            />
          </Box>
        }
        {
          showOp && showOp.op === 'signin_email' &&
          <SignInWithEmailPanel
            onCancel={() => setShowOp( null )}
            onOk={ async (email) => {

              try {

                if( email.length > 250 ) {

                  throw new Error( 'invalid email' );
                }

                await signInWithEmail( email );

                setShowOp( { op: 'email_sent' } )
              }
              catch( err ) {

                setShowOp( { op: 'error', error: 'Cannot sign in with the email that you provided' } );
              }
            }}
          />
        }
        {
          showOp && showOp.op === 'error' &&
          <Box background="white" round="small" pad="medium" gap="large">

            <Box pad="small">
              <Text>{showOp.error}</Text>
            </Box>
            <Box align="center">
              <Button primary label="Close" onClick={() => setShowOp( null )}/>
            </Box>
          </Box>
        }
        {
          showOp && showOp.op === 'email_sent' &&
          <Box background="white" round="small" pad="medium" gap="large">
            <Text>We've sent an activation link to your inbox. If you haven’t received it, check your spam folder.</Text>
          </Box>
        }
      </Box>
    </Box>
  );
};
export default SignIn;

import { nanoid } from 'nanoid';

import slugify from 'slugify';

export const createValueFromInput = ( input ) => {

  console.log( 'new value:', slugify( input ) );

  return slugify( input );
};

export const createOptionId = () => nanoid();

export const createItemId = () => nanoid();

export const createSectionId = () => nanoid();

//button.border.radius

const theme = {
  global: {
    colors: {

      brand: '#1280F8',
      'neutral-1': '#14213d',
      'accent-1': '#caf0f8',
    },
    font: {
      family: 'Roboto',
      size: '18px',
      height: '20px',
    },
    focus: {
      border: {
        color: 'brand'
      },
      outline: {

        color: 'transparent',
      },
    },
    input: {

      font: {

        weight: '400',
      },
    },
  },
  button: {

  },
  formField: {
    border: {
      error: {
        color: 'border',
      },
      color: 'border',
      side: 'all',
    },
    disabled: {
      background: {
        color: undefined,
      },
      border: {
        color: 'status-disabled',
      },
      label: {
        color: 'status-disabled',
      },
    },
    error: {
      background: {
        color: { light: '#FF404033', dark: '#FF40404D' },
      },
      size: 'xsmall',
      color: 'text-weak',
      margin: {
        start: 'none',
      },
    },
    help: {
      size: 'xsmall',
      color: 'text-weak',
      margin: {
        start: 'none',
        bottom: 'xsmall',
      },
    },
    info: {
      size: 'xsmall',
      color: 'text-weak',
      margin: {
        start: 'none',
      },
    },
    label: {
      size: 'small',
      color: 'text-weak',
      margin: {
        horizontal: 'none',
      },
    },
    round: '4px',
  },
  tab: {
    color: 'text-strong',
    active: {
      background: 'background-contrast',
    },
    border: {
      side: 'bottom',
      color: 'transparent',
      active: {
        color: 'brand',
        side: 'top',
      },
      disabled: {
        color: 'border-weak',
      },
      hover: {
        color: 'border',
      },
    },
    disabled: {
      color: 'text-weak',
    },
    hover: {
      background: 'background-contrast',
      color: 'text',
    },
    pad: 'small',
    margin: {
      horizontal: 'none',
    },
  },
};

export default theme;

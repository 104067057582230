import React, { useState } from 'react';

import { Box, Button, Form, FormField, TextInput } from 'grommet';
import { FormCheckmark, FormClose } from 'grommet-icons';

const OptionValueEdit = ({ optionValue, onCancel, onUpdate, hasPrice }) => {

  const [values, setValues ] = useState( () => {

      const { name = '', price = '' } = optionValue;

      if( ! hasPrice ) {

        return { name }
      }

      return { name, price };
    });

  const handleUpdate = () => {

    onUpdate( values );
  };

  return (

    <Box flex justify="center">
      <Form
        value={values}
        onChange={(nextValue) => setValues( nextValue )}
        validate="blur"
        onSubmit={handleUpdate}
      >
        <Box direction="row" align="center">
          <Box flex pad={{right: 'small'}}>
            <FormField name="name">
              <TextInput size="small" placeholder="option name" name="name"/>
            </FormField>
          </Box>
          {
            hasPrice &&
            <Box basis="xsmall">
              <FormField name="price">
                <TextInput size="small" textAlign="end" placeholder="price" name="price"/>
              </FormField>
            </Box>
          }
          <Box basis="xsmall" direction="row" justify="start">
            <Button
              size="small"
              icon={<FormCheckmark/>}
              type="submit"
            />
            <Button
              size="small"
              icon={<FormClose/>}
              onClick={onCancel}
            />
          </Box>
        </Box>
      </Form>
    </Box>
  )
};

export default OptionValueEdit;

import React, { useState } from 'react';

import { Box, Button,CheckBox, Form, FormField, Header, Select, Text, TextInput } from 'grommet';

import { optionType } from 'lib/formatters';

const TYPE_OPTION_VALUES = [ 'radio', 'checkbox' ].map( (value) => ({ label: optionType( value ), value }) );

const OptionEdit = ({option, onUpdate, onCancel}) => {

  const [values, setValues ] = useState( {

    name: option.name || '',
    type: { label: optionType( option.type ), value: option.type },
    price: option.price === true,
    price_additive: option.price_additive === true,
  });

  const handleUpdate = () => {

    const updatedValues = {

      name: values.name,
      type: values.type ? values.type.value : 'radio',
      price: values.price === true,
      price_additive: values.price_additive === true,
    };

    onUpdate( updatedValues );
  };

  return (

    <Box border round="small" gap="small" margin={{top:'small',bottom:'small'}} overflow="hidden">
      <Header pad="small" background="neutral-2">
        <Text><strong>{option.id ? 'Edit Option' : 'Add Option'}</strong></Text>
      </Header>
      <Box pad="small">
        <Form
          value={values}
          onChange={(nextValue) => setValues( nextValue )}
          validate="blur"
          onSubmit={handleUpdate}
        >
          <FormField name="name" label="Name" required>
            <TextInput placeholder="Name" name="name"/>
          </FormField>
          <FormField name="type" label="Type">
            <Select
              name="type"
              labelKey="label"
              valueKey="value"
              options={TYPE_OPTION_VALUES}
            >
            </Select>
          </FormField>
          <Box pad={{top: 'small', bottom: 'small'}} gap="small">
            <CheckBox
              name="price"
              label="Prices"
            />
            {values.price && <CheckBox
              name="price_additive"
              label="Prices are additive"
            />}
          </Box>
          <Box direction="row" justify="end" pad={{top: 'small'}} gap="small">
            <Button secondary label="Cancel" onClick={onCancel}/>
            <Button primary label={option.id ? 'Update' : 'Add Option'} type="submit"/>
          </Box>
        </Form>
      </Box>
    </Box>
  );
};

export default OptionEdit;

import React from 'react';

import { Box, Form, FormField, TextInput } from 'grommet';

import { useDispatch, useSelector } from 'react-redux';

import { selectMenuInfo } from 'selectors/menu';

import { updateMenuValues } from 'actions/menu';

const MenuPane = () => {

  const dispatch = useDispatch();

  const menu = useSelector( selectMenuInfo );

  const values = { name: menu.name || '' };

  const setValues = (updated) => {

    const { name } = updated;

    const updatedValues = { name };

    dispatch( updateMenuValues( updatedValues ) );
  };

  return (

    <Box gap="small" pad={{top: "medium"}}>
      <Form
        value={values}
        onChange={(nextValue) => setValues( nextValue )}
        validate="blur"
      >
        <FormField name="name" label="Name" required={true}>
          <TextInput name="name"/>
        </FormField>
      </Form>
    </Box>
  );
};

export default MenuPane;

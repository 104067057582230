import produce from 'immer';

const MATCH_ANY = '*';

const createReducer = ( handlerMap, initialState ) => produce( ( draft, action ) => {

    const handler = handlerMap[ action.type ] || handlerMap[ MATCH_ANY ];

    if( handler ) {

      return handler( draft, action.payload, action );
    }
  }, initialState
);

export const handleActions = ( handlerMap, initialState ) => {

  const reducer = createReducer( handlerMap, initialState );

  return reducer;
};

export default handleActions;

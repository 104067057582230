import firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAxZHaPcKP3EmMbFMdoiac6MsJgJlEnj3Q",
  authDomain: "candr-com-ca.firebaseapp.com",
  databaseURL: "https://candr-com-ca.firebaseio.com",
  projectId: "candr-com-ca",
  storageBucket: "candr-com-ca.appspot.com",
  messagingSenderId: "426006374774",
  appId: "1:426006374774:web:c1a4f97ed5d0d0c4397257",
  measurementId: "G-G9TM3E7NSE"
};

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const provider = new firebase.auth.GoogleAuthProvider();

export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

export const signInWithEmail = async (email) => {

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: `${window.location.protocol}//${window.location.host}/finishSignIn`,

    // This must be true.
    handleCodeInApp: true,
    // iOS: {
    //   bundleId: 'com.example.ios'
    // },
    // android: {
    //   packageName: 'com.example.android',
    //   installApp: true,
    //   minimumVersion: '12'
    // },
    dynamicLinkDomain: 'candrid.page.link'
  };

  await auth.sendSignInLinkToEmail( email, actionCodeSettings );

  window.localStorage.setItem( 'emailForSignIn', email );
}

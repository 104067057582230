import React, { useEffect, useState } from 'react';

import { Box, Button, Header, Heading, Layer, Paragraph, Text } from 'grommet';
import { Close } from 'grommet-icons';

import { useSelector, useDispatch } from 'react-redux';

import { publishMenu } from 'actions/publish';

import { selectSaveState } from 'selectors/save';
import { selectPublishState } from 'selectors/publish';

const PublishLayer = ({onClose}) => {

  const [ pubState, setPubState ] = useState( null );

  const dispatch = useDispatch();

  const { dirty } = useSelector( selectSaveState );
  const publishState = useSelector( selectPublishState );

  const doPublish = () => {

    if( pubState ) {

      return;
    }

    dispatch( publishMenu() );

    setPubState( 'publishing' );
  };

  useEffect( () => {

    if( pubState && !publishState.publishing ) {

      if( publishState.error ) {

        setPubState( 'error' );
        return;
      }
      
      setPubState( 'complete' );
    }
  }, [pubState, publishState] )

  return (

    <Layer
      position="right"
      full="vertical"
      modal
      onEsc={onClose}
    >
      <Box fill style={{ minWidth: '370px' }}>
        <Header background="neutral-2" pad="medium">
          <Heading level={2} pad="none" margin="none">Publish</Heading>
          <Button icon={<Close/>} disabled={pubState && pubState !== 'complete'} onClick={onClose}/>
        </Header>
        <Box gap="medium" pad="medium">
          <Paragraph margin="none">
            When a menu is published, it will become publically available via
            a specific URL.
          </Paragraph>
          <Box align="start" gap="small" margin={{top: 'medium'}}>
            {
              !pubState &&

              <Button
                disabled={dirty}
                label={dirty ? 'Waiting for save...' : 'Publish Now'}
                onClick={doPublish}
              />
            }
            {
              pubState === 'publishing' &&
              <Text>Publishing...</Text>
            }
            {
              pubState === 'error' &&
              <Box gap="small" align="start">
                <Text>Failed while trying to publish the menu.</Text>
                <Button label="Close" onClick={onClose}/>
              </Box>
            }
            {
              pubState === 'complete' &&
              <Box gap="small" align="start">
                <Text>Menu has been published</Text>
                <Button label="Close" onClick={onClose}/>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </Layer>
  )
};

export default PublishLayer;

import { isObject } from 'lodash';

const getValues = (payload, fields) => {

  if( fields ) {

    return fields.map( (key) => payload[ key ] );
  }

  return Object.values( payload );
}

const processValue = (value, type) => {

  if( isObject( value ) ) {

    return Object.keys( value );
  }

  if( Array.isArray( value ) ) {

    console.warn( 'Cannot create auto-group value for array, action type=', type );
    value = null;
  }

  return value;
}

const autoGrouper = ({type, payload}, meta ) => {

  const result = getValues( payload, meta.groupFields )
        .map( (value) => processValue( value, type ) )
        .filter( (value) => value != null );

  return [ type, result ].flat().join( '_' );
}

const grouper = ( action /*, res, history*/ ) => {

  const { meta } = action;

  if( !meta || !meta.undoGroup ) {

    return null;
  }

  if( meta.undoGroup === '$auto' ) {

    return autoGrouper( action, meta );
  }

  return meta.undoGroup;
};

export default grouper;

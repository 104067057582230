import { handleActions } from './handle-actions';

import { MENU_LOADED, MENU_SAVE, MENU_SAVED } from 'actions/menu';

import { UNDO, REDO } from 'actions/undo';

const markDirty = (draft) => {

  draft.dirty = true;
  draft.version = Date.now();
};

const DEFAULT_STATE = {

  saving: false,
  dirty: false,
  version: 0,
  saveCount: 0,
};


const reducer = handleActions( {

    [ MENU_LOADED ]: ( draft ) => (DEFAULT_STATE),

    [ MENU_SAVE ]: ( draft ) => {

      draft.saving = true;
    },

    [ MENU_SAVED ]: ( draft, { version } ) => {

      if( version === draft.version ) {

        // clear dirty only if the save version code matches out version code (background saves)
        draft.dirty = false;
      }

      draft.saveCount++;
      draft.saving = false;
    },

    '*': (draft, payload, action ) => {

      switch( action.type ) {

        case UNDO:
        case REDO:
          markDirty( draft );
          break;

        default:
          if( action.meta && (action.meta.saveable === true) ) {

            markDirty( draft );
          }
      }
    },
  },

  DEFAULT_STATE,
);

export default reducer;

import { call, takeEvery, takeLatest, put } from 'redux-saga/effects';

import { MENU_LOAD, MENU_ADD, menuLoaded, menuAdded } from 'actions/menu';

import { ActionCreators } from 'redux-undo';

import { getMenu, createMenu } from 'lib/api';

function* handleMenuLoad( action ) {

  const { payload: { id, history } } = action;

  try {

    const menu = yield call( getMenu, id );

    yield put( menuLoaded( id, menu ) );

    yield put( ActionCreators.clearHistory() );
  }
  catch( err ) {

    yield put( menuLoaded( id, {}, err.message ) );

    history.replace( '/' );
  }
}

function* handleMenuAdd( action ) {

  const { payload: { values, history } } = action;

  try {

    const menu = yield call( createMenu, values );

    const { id, name, logo, created, lastUpdated } = menu;

    yield put( menuAdded( { id, name, logo, created, lastUpdated } ) );

    history.push( `/menus/${id}` );
  }
  catch( err ) {

    yield put( menuAdded( null,  err.message ) );

    history.replace( '/' );
  }
}

export default function* menuSaga() {

  //yield takeEvery( LOCATION_SELECT_IDENTITY, handleLocationState );
  yield takeLatest( MENU_LOAD, handleMenuLoad );

  yield takeEvery( MENU_ADD, handleMenuAdd );
}

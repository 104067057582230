import { createSelector } from 'reselect';

const getIsPublishing = state => state.publish.publishing;

const getLastPublished = state => state.publish.lastPublished;

const getError = state => state.publish.error;

export const selectPublishState = createSelector(

  getIsPublishing,
  getLastPublished,
  getError,
  (publishing, lastPublished,error) => ( { publishing, lastPublished, error } )
);

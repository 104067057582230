import { handleActions } from './handle-actions';

import { USER_LOGIN, USER_LOGIN_COMPLETE,  USER_LOGOUT } from 'actions/user';

const DEFAULT_STATE = {

  initializeState: 'not_initialized',
};

const reducer = handleActions( {

    [ USER_LOGOUT ]: ( draft ) => {

      draft.initializeState = 'not_initialized';
    },

    [ USER_LOGIN ]: ( draft, { complete} ) => {

      draft.initializeState = 'initializing';
    },

    [ USER_LOGIN_COMPLETE ]: ( draft ) => {

      draft.initializeState = 'initialized';
    }
  },
  DEFAULT_STATE,
);

export default reducer;

import { createStore, compose, applyMiddleware } from 'redux';

import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';

import rootSaga from './sagas';

if (process.env.NODE_ENV !== 'production') {

  Map.prototype.toJSON = function() {
    var obj = {};
    this.forEach((value, key) => obj[key] = value);
    return obj;
  }
}

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = process.env.NODE_ENV === "production" ? compose : composeWithDevTools;

const enhancers = composeEnhancers(

  applyMiddleware( sagaMiddleware ),
);

export function configureStore( preloadedState) {

  const store = createStore(
    rootReducer(),
    preloadedState,
    enhancers
  );

  sagaMiddleware.run(rootSaga);

  return store;
};


import React, { useContext } from 'react';

import { Box, Button, Text } from 'grommet';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { MenuContext, MenuHeader, MenuItem, Section } from '@candr-com/menu-common-ui';

const SECTION_ID_PREFIX = 'section:';

const Items = ({ section, selected, onSelect, onAdd, preview }) => {

  return (
    <Droppable droppableId={`${SECTION_ID_PREFIX}${section.id}`} type={`ITEMS`}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
        >
          {section.items.map((item, index) => (
            <Draggable
              key={item.id}
              draggableId={item.id}
              index={index}
              isDragDisabled={(selected.item !== item.id) || preview}
            >
              {(provided, snapshot) => (
                <div
                  className="menu_item"
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <MenuItem

                    item={item}
                    selected={selected.item === item.id && !preview}
                    onClick={() => selected.item !== item.id && !preview && onSelect( { item: item.id } )}
                  />
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
          <div style={{height: 1}}>&nbsp;</div>
          {
            !preview &&
            <div>
              <Box pad="small" align="start">
                <Button size="small" label="Add Item" onClick={() => onAdd({ item: { sectionId: section.id } })}/>
              </Box>
            </div>
          }
        </div>
      )}
    </Droppable>
  );
};


const handleOnDragEnd = (result, onMove ) => {

  const { source, destination, type } = result;

  if( !destination ) {

    return;
  }

  const from = source.index;
  const to = destination.index;

  if( source.droppableId === destination.droppableId ) {

    // dnd within same section

    if( type === 'ITEMS' ) {

      if( from === to ) {

        // not moving
        return;
      }

      const sectionId = source.droppableId.substring( SECTION_ID_PREFIX.length );

      onMove( {

        item: {
          fromSection: sectionId,
          from,
          to,
          toSection: sectionId,
        }
      });

      return;
    }

    if( type === 'SECTIONS' ) {

      if( from === to ) {

        // not moving
        return;
      }
      
      onMove( {

        section: {

          from,
          to,
        }
      });
    }
  }

  if( source.droppableId !== destination.droppableId ) {

    if( type === 'ITEMS' ) {

      const sourceSectionId = source.droppableId.substring( SECTION_ID_PREFIX.length );
      const destSectionId = destination.droppableId.substring( SECTION_ID_PREFIX.length );

      onMove( {

        item: {
          fromSection: sourceSectionId,
          from,
          to,
          toSection: destSectionId,
        }
      });
    }
  }
};

const Sections = ({sections, selected, onSelect, onAdd, onMove, reorderSections, preview }) => {

  return (
    <DragDropContext
      onDragEnd={(result) => handleOnDragEnd( result, onMove )}
      onDragUpdate={() => {}}
    >
      <Droppable droppableId="droppable" type="SECTIONS">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
          >
            {sections.map((section, index) => (
              <Draggable
                key={section.id}
                draggableId={section.id}
                index={index}
                isDragDisabled={!reorderSections || preview}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                  >
                    <Section
                      section={section}
                      selected={!preview && selected.section === section.id}
                      onClick={() => !preview && onSelect( { section: section.id })}
                    />
                    {
                      !reorderSections &&
                      <Box border={preview ? false : 'bottom'} margin={{bottom: 'small'}}>
                        <Items
                          section={section}
                          selected={selected}
                          onSelect={onSelect}
                          onAdd={onAdd}
                          preview={preview}
                        />
                      </Box>
                    }
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const MenuEditor = ( { header, sections, selected = {}, onSelect, onAdd, onMove, reorderSections, preview }) => {

  const { theme } = useContext( MenuContext) || {};

  const { background = {} } = theme;

  return (
    <Box overflow='auto' background={ background.color || 'white' }>
      <div>
        <MenuHeader
          header={header}
          selected={selected.header !== undefined}
          onClick={() =>  onSelect({ header: {} })}
          emptyLogo={
            <Box height={{min: "100px", max: "100px"}} pad="small" justify="center" align="center">
              <Box border={{side: 'all', style: 'dotted'}} pad="small">
                <Text>Insert Logo Here</Text>
              </Box>
            </Box>
          }
        />
      </div>
      <Sections
        sections={sections}
        selected={selected}
        onSelect={onSelect}
        onAdd={onAdd}
        onMove={onMove}
        reorderSections={reorderSections}
        preview={preview}
      />
      {
        !preview && !reorderSections &&
        <div>
          <Box pad="small" align="start">
            <Button size="small" label="Add Section" onClick={() => onAdd({ section: {} })}/>
          </Box>
        </div>
      }
    </Box>
  )
};

export default MenuEditor;

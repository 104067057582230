import React, { useCallback, useState } from 'react';

import { Box, Image, Text } from 'grommet';

import axios from 'axios';

import { useDispatch, useSelector } from 'react-redux';

import { selectMenuInfo } from 'selectors/menu';

import { updateMenuLogo } from 'actions/menu';

import { useDropzone } from 'react-dropzone';

import { uploadMenuAssetParams } from 'lib/api';

import Spinner from 'components/Spinner';

const FileUpload = ({existing,onUpload}) => {

  const [ uploading, setUploading ] = useState( false );

  const onDrop = useCallback( async ( [ file ]) => {

      try {

        setUploading( true );

        await onUpload( file );
      }
      catch( err ) {

        console.log( err );
      }
      finally {

        setUploading( false );
      }
    }, [ onUpload ])

  const {getRootProps, getInputProps} = useDropzone({

      onDrop,
      maxSize: 1000000, // 1MB
      accept: 'image/*',
      multiple: false,
      disabled: uploading,
    })

  return (
    <div {...getRootProps()} style={{width: 175, height: 100, display: 'flex', alignItems: 'center', justifyContent: 'center', borderWidth: 1, borderStyle: 'dotted'}}>
      <input {...getInputProps()} />
      {
         !existing && !uploading &&
        <Text size="small">Select Image</Text>
      }
      {
        existing && !uploading &&
        <Image
          fit="contain"
          src={existing}
          height={100}
        />
      }
      {
        uploading &&
        <Spinner size={48}/>
      }
    </div>
  )
};

const HeaderPane = () => {

  const dispatch = useDispatch();

  const { id, logo: currentLogo } = useSelector( selectMenuInfo );

  return (

    <Box gap="small" pad={{top: "medium"}}>

      <Box direction="row">
        <Box align="start" gap="small">
          <Text>Logo</Text>
          <FileUpload existing={currentLogo} onUpload={ async (file) => {

            const params = await uploadMenuAssetParams( id, 'logo' );

            const { name, upload } = params;

            const formData = new FormData();

            Object.entries( upload.fields ).forEach( ([key,value]) => formData.append( key, value ) );
            formData.append( 'Content-Type', file.type );
            formData.append( 'file', file );

            await axios.post( upload.url, formData );

            dispatch( updateMenuLogo( name ) );
          }}/>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderPane;

import React from 'react';

import { Box, Button, Header, Main } from 'grommet';

import { auth } from '../firebase';

//import preval from 'preval.macro'

function MainContainer( { center, children } ) {

  return (<Box fill>
      <Header background="neutral-1" pad="medium">
        <Box direction="row" basis="small">
          <span style={{color: 'white', fontSize: 28, fontWeight: 600}}>candr</span>
          <span style={{paddingLeft: 4,  alignContent: 'center'}}>
            <span style={{color: '#fca311', fontSize: 24, fontWeight: 800}}>MENU</span>
          </span>
        </Box>
        <Box justify="center" flex>
          {center}
        </Box>
        <Box direction="row-reverse" align="end" basis="small">
          <Button plain onClick={() => auth.signOut()} label="Sign Out"/>
        </Box>
      </Header>
      <Main flex>
        {children}
      </Main>
    </Box>
  );
}

export default MainContainer;

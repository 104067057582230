import { take, takeLatest, put } from 'redux-saga/effects';

import { USER_LOGIN, userLoginComplete } from 'actions/user';

import { loadMenus } from 'actions/menus';

function* handleLogin( action ) {

  // yield put( loadLocations() );
  //
  // yield take( LOCATIONS_LOADED );
  //

  yield put( userLoginComplete() );

  yield put( loadMenus() );
}

export default function* userSaga() {

  yield takeLatest( USER_LOGIN, handleLogin );
}

import React, { useState, useEffect } from 'react';

import { Box, Button, Header, Tab, Tabs, Text } from 'grommet';
import { Brush, FormPreviousLink, Trash, Redo, Share, Undo } from 'grommet-icons';

import { useDispatch, useSelector } from 'react-redux';

import { undo, redo } from 'actions/undo';
import { loadMenu,
         addSection, deleteSection,
         addMenuItem, moveMenuItem, moveSection, deleteMenuItem,
       } from 'actions/menu';

import { setSelected, setSectionReorder } from 'actions/menu_edit';

import { selectMenuInfo, selectSections, selectIsLoading } from 'selectors/menu';
import { selectSelectedValues, selectIsReorderSections } from 'selectors/menu_edit';
import { selectUndoRedoEnabled } from 'selectors/undo';

import { selectSaveState } from 'selectors/save';

import { createSectionId, createItemId } from 'lib/id';

import Spinner from 'components/Spinner';

import MenuEditor from './MenuEditor';

import { MenuContext } from '@candr-com/menu-common-ui';

import MenuPane from './MenuPane';
import MenuItemPane from './MenuItemPane';
import OptionsPane from './OptionsPane';
import SectionPane from './SectionPane';
import HeaderPane from './HeaderPane';
import ThemePane from './ThemePane';

import PublishLayer from './PublishLayer';

const doSelect = (dispatch, {item, section, header } ) => {

  if( item ) {

    dispatch( setSelected( { item: { id: item } }, true /*replace*/ ) );
    return;
  }

  if( section ) {

    dispatch( setSelected( { section: { id: section } }, true /*replace*/ ));
    return;
  }

  if( header ) {

    dispatch( setSelected( { header: {} }, true /*replace*/ ) );
  }
};

const doAdd = (dispatch, { item, section } ) => {

  if( item ) {

    const id = createItemId();

    dispatch( addMenuItem( item.sectionId, { id, name: 'New Item' } ) );
  }

  if( section ) {

    const id = createSectionId();

    dispatch( addSection( id, { name: 'New Section'} ) );
  }
};

const doMove = (dispatch, {item, section}) => {

  if( item ) {

    dispatch( moveMenuItem( item.fromSection, item.from, item.to, item.toSection ) );
  }

  if( section ) {

    dispatch( moveSection( section.from, section.to ) );
  }
};

const SaveStatus = () => {

  const { dirty, saving } = useSelector( selectSaveState );

  const state = !dirty ? 'saved' : saving ? 'saving' : 'changed';

  const ui = {

    saved: { background: 'status-ok', text: 'Saved' },
    saving: { background: '#00b4d8', text: 'Saving...' },
    changed: { background: 'status-warning', text: 'Changed' },
  };

  return (
    <Box round="small" background={ui[state].background} pad={{horizontal: 'small', vertical: 'xxsmall'}}>
      <Text size="small" color="white">{ui[state].text}</Text>
    </Box>
  );
};

const MenuPage = ({history, match}) => {

  const { params: { id: pageMenuId } } = match;

  const dispatch = useDispatch();

  const [mode, setMode] = useState( null );

  const isLoading = useSelector( selectIsLoading );

  const selectedValues = useSelector( selectSelectedValues );

  const {
      item: selectedItem,
      section: selectedSection,
      header
    } = selectedValues;

  const [isUndo, isRedo] = useSelector( selectUndoRedoEnabled );

  const reorderSections = useSelector( selectIsReorderSections );

  const { id: menuId, name: menuName, logo, theme } = useSelector( selectMenuInfo );

  const sections = useSelector( selectSections );

  const canDelete = (selectedItem?.id || selectedSection?.id) !== undefined;

  const handleOnSelect = (selection) => doSelect( dispatch, selection );

  const handleOnAdd = (addEvent) => doAdd( dispatch, addEvent );

  const handleOnMove = (moveEvent) => doMove( dispatch, moveEvent );

  const handleOnDelete = () => {

    if( selectedItem ) {

      dispatch( deleteMenuItem( selectedItem.id ) );
      return;
    }

    if( selectedSection ) {

      dispatch( deleteSection( selectedSection.id ) );
      return;
    }
  }

  useEffect( () => {

    if( pageMenuId !== menuId ) {

      dispatch( loadMenu( pageMenuId, history ) );
    }
  }, [pageMenuId, menuId, dispatch, history] );

  if( !menuId || isLoading ) {

    return (
      <Box fill align="center" justify="center">
        <Spinner size={48}/>
      </Box>
    );
  }

  const renderRightSide = () => {

    if( reorderSections ) {

      return (
        <Box flex>
          <Box gap="large" align="start">
            <Text>Drag and drop the menu sections. Click "done" when complete</Text>
            <Button label="Done" onClick={() => dispatch( setSectionReorder( false ) )}/>
          </Box>
        </Box>
      );
    }

    if( mode === 'theme' ) {

      return (
        <Box flex>
          <ThemePane onDone={() => setMode( null )}/>
        </Box>
      );
    }

    if( selectedItem ) {

      return (
        <Tabs alignControls="start" flex>
          <Tab title="Item">
            <MenuItemPane item={selectedItem}/>
          </Tab>
          <Tab title="Options">
            <OptionsPane item={selectedItem}/>
          </Tab>
          <Tab title="Menu">
            <MenuPane/>
          </Tab>
        </Tabs>
      )
    }

    if( selectedSection ) {

      return (
        <Tabs alignControls="start" flex>
          <Tab title="Section">
            <SectionPane section={selectedSection}/>
          </Tab>
          <Tab title="Menu">
            <MenuPane/>
          </Tab>
        </Tabs>
      );
    }

    if( header ) {

      return (
        <Tabs alignControls="start" flex>
          <Tab title="Header">
            <HeaderPane/>
          </Tab>
          <Tab title="Menu">
            <MenuPane/>
          </Tab>
        </Tabs>
      );
    }

    // just menu settings
    return (
      <Tabs alignControls="start" flex>
        <Tab title="Menu">
          <MenuPane/>
        </Tab>
      </Tabs>
    );
  };

  return (
    <Box fill>
      <Header pad={{left:'none', right: 'small', top: 'small', /*bottom: 'small'*/}} border={{side: 'bottom', size: '1px', color:'light-3'}} align="center">
        <Box direction="row" align="center">
          <Button icon={<FormPreviousLink/>} onClick={() => history.replace( '/' )}/>
          <Text size="large" weight="bold">{menuName}</Text>
        </Box>
        <Box direction="row" align="center" gap="small">
          <SaveStatus/>
          <Box direction="row" align="center" gap="small" margin={{horizontal: 'small'}}>
            <Button disabled={!isUndo} icon={<Undo/>} onClick={() => dispatch( undo( history ))}/>
            <Button disabled={!isRedo} icon={<Redo/>} onClick={() => dispatch( redo( history ))}/>
          </Box>
          <Button disabled={!canDelete} icon={<Trash/>} onClick={handleOnDelete}/>
          <Button icon={<Brush/>} onClick={() => setMode( mode !== 'theme' ? 'theme' : null )}/>
          <Button icon={<Share/>} onClick={() => setMode( 'publish' )}/>
        </Box>
      </Header>
      <Box pad="small" direction="row" gap="medium" flex>
        <Box round="small" border={{side: 'all', color: 'light-3'}} width="375px" overflow="hidden" elevation="small" pad="xsmall">
          <MenuContext.Provider value={{theme}}>
            <MenuEditor
              header={{ logo }}
              sections={sections}
              selected={{

                item: selectedValues?.item?.id,
                section: selectedValues?.section?.id,
                header: selectedValues?.header,
              }}
              onAdd={handleOnAdd}
              onSelect={handleOnSelect}
              onMove={handleOnMove}
              reorderSections={reorderSections}
              preview={mode === 'theme'}
            />
          </MenuContext.Provider>
        </Box>
        { renderRightSide() }
      </Box>
      {
        mode === 'publish' &&
        <PublishLayer onClose={() => setMode( null )}/>
      }
    </Box>
  )
};

export default MenuPage;

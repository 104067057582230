import { createAction as _createAction } from 'redux-actions';

export const createAction = _createAction;

export const createSaveableAction = (type, payloadCreator, metaCreator ) => {

  const outerMetaCreator = () => {

    const meta = metaCreator ? metaCreator() : {};

    return { saveable: true, ...meta };
  };

  return createAction( type, payloadCreator, outerMetaCreator );
}


export const metaNoUndo = () => ({undoable: false});

export const metaUndoGroup = ({undoGroup = '$auto', groupFields }) => () => ({ undoGroup, groupFields }) ;

export const createActionNoUndo = (type, payloadCreator ) =>
    createAction( type, payloadCreator, metaNoUndo );

export const createActionGroupUndo = (type, payloadCreator, group = {} ) =>
    createSaveableAction( type, payloadCreator, metaUndoGroup( group ) );

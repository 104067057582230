import React, { useState } from 'react';

import { Avatar, Box, Button, Heading } from 'grommet';
import { FormTrash } from 'grommet-icons';

import { useDispatch, useSelector } from 'react-redux';

import { addMenu } from 'actions/menu';

import { selectMenus, selectIsLoading } from 'selectors/menus';

import Spinner from 'components/Spinner';

const MenuSelector = ({menu, onSelect, extra}) => {

  const { id, name = '', logo } = menu;

  return (
    <Box direction="row" align="center" gap="small" onClick={() => onSelect( id )}>
      {
         logo &&
         <Avatar size="medium" src={logo}/>
      }
      {
        !logo &&
        <Avatar size="medium" background="dark-2">{name.substring(0,1)}</Avatar>
      }
      <Heading
        key={menu.id}
        level={3}
        pad="none"
        margin="none"
      >
        {name}
      </Heading>
      <Box direction="row-reverse" flex>
        {extra}
      </Box>
    </Box>
  );
};

const MenusPage = ({history}) => {

  const dispatch = useDispatch();

  const loading = useSelector( selectIsLoading );
  const menus = useSelector( selectMenus );

  if( loading ) {

    return (

      <Box fill align="center" justify="center">
        <Spinner size={48}/>
      </Box>
    );
  }
  return (

    <Box pad="medium" gap="small">
      <Heading level={3}>Menus</Heading>
      <Box gap="medium">
        <Box gap="small">
          {
            menus.map( (menu, index) => (

              <MenuSelector key={menu.id} menu={menu} onSelect={(id) => history.push( `/menus/${menu.id}` )}/>
            ))
          }
        </Box>
        <Box align="start">
          <Button secondary label="Add Menu" onClick={() => dispatch( addMenu( { name: 'New Menu' }, history ))}/>
        </Box>
      </Box>
    </Box>
  );
};

export default MenusPage;

import { createSelector } from 'reselect';

const getVersion = state => state.save.version;

const getIsDirty = state => state.save.dirty;

const getIsSaving = state => state.save.saving;

const getSaveCount = state => state.save.saveCount;

export const selectSaveState = createSelector(

  getVersion,
  getIsDirty,
  getIsSaving,
  getSaveCount,
  (version, dirty, saving, saveCount ) => ( { version, dirty, saving, saveCount } )
);

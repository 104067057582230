import { call, takeLatest, put } from 'redux-saga/effects';

import { MENUS_LOAD, menusLoaded } from 'actions/menus';

import { getMenus } from 'lib/api';

function* handleMenusLoad( action ) {

  try {

    //const menu = testMenu;

    const menus = yield call( getMenus );

    yield put( menusLoaded( menus ) );
  }
  catch( err ) {

    yield put( menusLoaded( [], err.message ) );
  }
}

export default function* menusSaga() {

  //yield takeEvery( LOCATION_SELECT_IDENTITY, handleLocationState );
  yield takeLatest( MENUS_LOAD, handleMenusLoad );
}

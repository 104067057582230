export const price = (value) => {

  const num = +value;

  if( isNaN( num ) ) {

    return 'invalid price';
  }

  return num;
}

export { formatPrice } from '@candr-com/menu-common-ui';

const OPTION_TYPES = {

  radio: 'Single Value',
  checkbox: 'Multiple Value',
};

export const optionType = (type) => {

  return OPTION_TYPES[ type ] || 'Unknown';
};

import React, { useState, useMemo } from 'react';

import { Box, Button, Header, Menu, Text } from 'grommet';
import { FormEdit, FormTrash} from 'grommet-icons';

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { useDispatch, useSelector } from 'react-redux';

import { formatPrice, optionType } from 'lib/formatters';

import { createOptionId } from 'lib/id';

import { moveMenuItemOption,
        addOption, updateOption, deleteOption,
        updateOptionValue, moveOptionValue, deleteOptionValue
       } from 'actions/menu';

import { setSelected } from 'actions/menu_edit';

import { selectSelected } from 'selectors/menu_edit';

import OptionEdit from './OptionEdit';
import OptionValueEdit from './OptionValueEdit';

const OptionValueItem = ({ optionValue, selected, onEdit, onDelete, dragging, hasPrice }) => {

  return (
    <>
      <Box direction="row" pad={{left: 'xsmall'}} gap="small" flex align="center">
        <Text size="small"><strong>{optionValue.name}</strong></Text>
        {false && selected && <Text size="xsmall">({optionValue.value})</Text>}
      </Box>
      {
        hasPrice &&
        <Box basis="xsmall">
          {optionValue.price && <Text textAlign="end" size="small">{formatPrice( optionValue.price )}</Text>}
        </Box>
      }
      <Box basis="xsmall" direction="row" justify="start">
        {
          selected && !dragging &&
          <>
            <Button
              size="small"
              icon={<FormEdit/>}
              onClick={(e) => {
                e.preventDefault();
                onEdit();
              }}
            />
            <Button
              size="small"
              icon={<FormTrash/>}
              onClick={onDelete}
            />
          </>
        }
      </Box>
    </>);
};

const OptionValue = ({ optionValue, index, onSelect, selected, edit, onEdit, onDelete, onUpdate, hasPrice }) => {

  return (
    <Draggable draggableId={optionValue.value} index={index} isDragDisabled={!selected && !edit}>
      {(provided,snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}

          xfocusIndicator={false}
          direction="row" align="center"
          onClick={(e) => {

            if( !edit && !e.defaultPrevented ) {

              e.preventDefault();
              onSelect();
            }
          }}
          background={selected && !edit && 'accent-1'}
          border={false}
          height="xxsmall"
        >
          { !edit && <OptionValueItem optionValue={optionValue} selected={selected} onEdit={onEdit} onDelete={onDelete} hasPrice={hasPrice} dragging={snapshot.isDragging}/> }
          { edit && <OptionValueEdit optionValue={optionValue} onCancel={onSelect} onUpdate={onUpdate} hasPrice={hasPrice}/> }
        </Box>
      )}
    </Draggable>
  );
}

const renderOptionInfoText = (option) => {

  const items = [ optionType( option.type), option.price ? 'Has Prices' : 'No Prices' ];

  if( option.price && option.price_additive ) {

    items.push( 'Price Addititve' );
  }

  return items.join( ', ' );
}

const OptionPanel = ({

    option, selectedItem, onSelectItem, onEditItem, onUpdateItem, onDeleteItem,
    onMoveItem, index, last, onDelete, onMove,
  }) => {

  const [ addValue, setAddValue ] = useState( null );

  const isSelected = (selectedItem && selectedItem.option === option.id);

  const handleDragEnd = (result) => {

    if( !result.destination ) {
      return;
    }

    const from = result.source.index;
    const to = result.destination.index;

    if( from !== to ) {

      onMoveItem( from, to );

      // update selection
      onSelectItem( { option: option.id, valueIndex: to })
    }
  };

  const menuItems = useMemo( () => {

    const items = [];

    if( index > 0 ) {

      items.push( { label: 'Move Up', onClick: () => onMove( index-1 ) } );
    }

    if( !last ) {

      items.push( { label: 'Move Down', onClick: () => onMove( index+1 ) } );
    }

    if( items.length > 0 ) {

      items.push( { label: <Box flex border="all"/> } );
    }

    items.push( { label: 'Delete', onClick: () => onDelete() } );

    return items;
  }, [index, last, onDelete, onMove] );

  return (
    <Box border round="small" pad="small" gap="small" margin={{top:'small',bottom:'small'}} overflow="hidden">
      <Header border={{side: 'bottom'}} pad={{bottom: 'small'}}>
        <Box direction="row" align="center" gap="small">
          <Text><strong>{option.name}</strong></Text>
          <Text size="xsmall">({renderOptionInfoText( option )})</Text>
          <Button plain icon={<FormEdit/>} onClick={() => onSelectItem( { option: option.id, editOption: true } )}/>
        </Box>
        <Menu
        dropProps={{
          align: { top: 'bottom', right: 'right' },
          elevation: 'large',
          }}
          items={menuItems}
        />
      </Header>
      <Box>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="list">
            {provided => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {
                  option.options.map( (optionValue, index) => (
                    <OptionValue
                      key={optionValue.value}
                      optionValue={optionValue}
                      index={index}
                      onSelect={() => onSelectItem( { option: option.id, valueIndex: index })}
                      selected={isSelected && selectedItem.valueIndex === index}
                      edit={isSelected && selectedItem.valueIndex === index && selectedItem.edit}
                      onDelete={() => onDeleteItem( index )}
                      onEdit={() => onEditItem( optionValue )}
                      onUpdate={(values) => onUpdateItem( index, values )}
                      hasPrice={option.price}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        {
          addValue &&
          <OptionValueEdit
            optionValue={addValue}
            onUpdate={(values) => {

              onUpdateItem( null, values );
              setAddValue( null );
            }}
            onCancel={() => setAddValue( null )}
            hasPrice={option.price}
          />
        }
        {
          !addValue &&
          <Box align="start" pad={{top: 'small'}}>
            <Button size="small" label="Add Value" onClick={() => {

              onSelectItem( { option: option.id } );
              setAddValue( { } );
            }}/>
          </Box>
        }
      </Box>
    </Box>
  );
};

const OptionsPane = ({item}) => {

  const dispatch = useDispatch();

  const { option: selectedItem } = useSelector( selectSelected );

  const [ addItem, setAddItem ] = useState( null );

  const { options } = item;

  const editOptionId = (selectedItem && selectedItem.editOption && selectedItem.option);

  const updateSelected = ( selected ) => {

    dispatch( setSelected( { option: selected } ) );
  };

  return (

    <Box gap="small" pad={{top: "medium"}}>
      {
        options && options.map( (option, index ) => {

          if( editOptionId === option.id ) {

            return (

              <OptionEdit
                key={option.id}
                option={option}
                onCancel={() => updateSelected( null )}
                onUpdate={(values) => {

                  // dispatch
                  dispatch( updateOption( option.id, values ) );
                  updateSelected( null );
                }}
              />);
          }

          return (
            <OptionPanel
              key={option.id}
              option={option}
              selectedItem={selectedItem}
              index={index}
              last={index === options.length -1}
              onSelectItem={(panelItem) => updateSelected( panelItem )}
              onDeleteItem={(valueIndex) => dispatch( deleteOptionValue( option.id, valueIndex ) )}
              onEditItem={() => updateSelected( { ...selectedItem, edit: true })}
              onMoveItem={(from, to) => dispatch( moveOptionValue( option.id, from, to ))}
              onUpdateItem={(valueIndex,values) => {

                dispatch( updateOptionValue( option.id, valueIndex, values ));
                updateSelected( {...selectedItem, edit: false } ); // kill editing
              }}
              onMove={(to) => dispatch( moveMenuItemOption( item.id, index, to ))}
              onDelete={() => dispatch( deleteOption( option.id ) )}
            />
          );
        })
      }
      {
        !addItem && !editOptionId &&
        <Box align="start" margin={{top: 'medium'}}>
          <Button label="Add Option" onClick={() => setAddItem( { type: 'radio', options: [] } )}/>
        </Box>
      }
      {
        addItem &&
        <OptionEdit
          option={addItem}
          onCancel={() => setAddItem( null )}
          onUpdate={(values) => {

            const optionId = createOptionId();

            dispatch( addOption( optionId, values, item.id ));
            setAddItem( null );
          }}
        />
      }
    </Box>
  );
};

export default OptionsPane;

import { takeEvery, takeLatest, call, debounce, delay, put, select } from 'redux-saga/effects';

import { MENU_SAVE, saveMenu, menuSaved } from 'actions/menu';

import { UNDO, REDO } from 'actions/undo';

import { selectMenuForSave } from 'selectors/menu';

import { updateMenu } from 'lib/api';

import { selectSaveState } from 'selectors/save';

function* handleSave( saveAction ) {

  const { payload: { autosave = false, deploy } } = saveAction;

  const { version, dirty, saving } = yield select( selectSaveState );

  if( !autosave && !deploy && (!dirty || saving) ) {

    // not dirty or already saving
    yield put( menuSaved( 0, null, 'not dirty' ) );
    return;
  }

  try {

    const menu = yield select( selectMenuForSave );

    const menuData = { ...menu };

    if( deploy ) {

      menuData.deploy = deploy;
    }

    const result = yield call( updateMenu, menu.id, menuData );

    console.log( 'saved' );

    yield put( menuSaved( version, result.menu ) );
  }
  catch( err ) {

    console.log( err );
    yield put( menuSaved( 0, null, err.message ) );
  }
}

function* handleAutosave() {

  const { dirty, saving } = yield select( selectSaveState );

  if( dirty && !saving ) {

    yield put( saveMenu( true /* via autosave */) );
  }
}

const isSavableAction = ( action ) => {

  const { type, meta = { saveable: false } } = action;

  switch( type ) {

    case UNDO:
    case REDO:
      return true;

    default:
      return (meta.saveable === true);
  }
}

export default function* saveSaga() {

  yield debounce( 1500, isSavableAction, handleAutosave );
  yield takeEvery( MENU_SAVE, handleSave );
}

export const presentState = ( selector ) => (state) => selector( state.current_menu.present );


export const renderItem = ( {options, ...item}, optionsMap ) => {

  const renderedItem = { ...item };

  if( options && options.length > 0 ) {

    renderedItem.options = options.map( (optId) => optionsMap.get(optId) );
  }

  return renderedItem;
}

export const renderSection = (section, itemsMap ) => {

  const renderedSection = {

    ...section,
    items: section.items.filter( (id) => itemsMap.has( id )).map( (id) => itemsMap.get( id )  )
  };

  return renderedSection;
};

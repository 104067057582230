import axios from 'axios';

import { auth } from '../firebase';

const BASE_URL = 'https://api.menu.candr.com/v1';

const makeURL = ( path ) => {

  return BASE_URL + path;
};

const authHeaders = async () => {

  const result = await auth.currentUser.getIdTokenResult();

  const { token } = result;

  return {
      'Authorization': `Bearer ${token}`,
  };
};

const updateConfig = async ( config = {} ) => {

  const headers = await authHeaders();

  const cfg = { ...config };

  cfg.headers = cfg.headers ? { ...headers, ...cfg.headers } : headers;

  return cfg;
};

const GET = async ( path, config ) => {

  return await axios.get( makeURL( path ),  await updateConfig( config ) );
};

const POST = async ( path, data, config ) => {

  return await axios.post( makeURL( path ), data, await updateConfig( config ) );
};

const PUT = async ( path, data, config ) => {

  return await axios.put( makeURL( path ), data, await updateConfig( config ) );
};

export const getMenus = async () => {

  const { data: menus } = await GET( '/menus' );

  return menus;
};

export const getMenu = async ( id ) => {

  const { data: menu } = await GET( `/menus/${id}` );

  return menu;
};

export const updateMenu = async( id, menu ) => {

  const { data: result } = await PUT( `/menus/${id}`, menu );

  return result;
}

export const uploadMenuAssetParams = async ( id, type ) => {

  const { data: params } = await PUT( `/menus/${id}/assets/${type}` );

  return params;
};


export const createMenu = async ( values ) => {

  const { data: menu } = await POST( '/menus', values );

  return menu;
};

//
// export const updateLocation = async ( id, values ) => {
//
//   const { data: location } = await PUT( , values );
//
//   return location;
// };
//
// export const uploadLocationAssetParams = async ( id, type ) => {
//
//   const { data: params } = await PUT( `/locations/${id}/assets/${type}` );
//
//   return params;
// };
//
// export const getLocationActivity = async ( id ) => {
//
//   const { data: activity } = await GET( `/locations/${id}/activity` );
//
//   return activity;
// };

import { all } from 'redux-saga/effects';

import userSaga from './user';
import menuSaga from './menu';
import menusSaga from './menus';
import saveSaga from './save';
import publishSaga from './publish';

export default function* rootSaga() {

  yield all([
    userSaga(),
    menuSaga(),
    menusSaga(),
    saveSaga(),
    publishSaga(),
  ]);
}

const filter = (action) => {

  if( action.meta && action.meta.undoable !== undefined ) {

    return action.meta.undoable;
  }

  return true;
};

export default filter;

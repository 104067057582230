import { createAction, createSaveableAction, createActionGroupUndo } from './helper';


export const MENU_ADD = 'MENUS_ADD';
export const MENU_ADDED = 'MENU_ADDED';
export const MENU_DELETE = 'MENUS_DELETE';

export const addMenu = createAction( MENU_ADD, (values, history) => ({values, history}) );
export const menuAdded = createAction( MENU_ADDED, (menu, error) => ({menu, error}) );

export const MENU_LOAD = 'MENU_LOAD';
export const MENU_LOADED = 'MENU_LOADED';
export const MENU_SAVE = 'MENU_SAVE';
export const MENU_SAVED = 'MENU_SAVED';

export const loadMenu = createAction( MENU_LOAD, (id, history) => ({ id, history }) );
export const menuLoaded = createAction( MENU_LOADED, (id, data, error ) => ({ id, data, error }) );
export const saveMenu = createAction( MENU_SAVE, (autosave, deploy) => ({autosave, deploy}) );
export const menuSaved = createAction( MENU_SAVED, (version, menuInfo, error) => ({version, menuInfo, error }) );

export const MENU_UPDATE_VALUES = 'MENU_UPDATE_VALUES';
export const MENU_UPDATE_LOGO = 'MENU_UPDATE_LOGO';
export const MENU_UPDATE_THEME = 'MENU_UPDATE_THEME';

export const updateMenuValues = createActionGroupUndo( MENU_UPDATE_VALUES, (values) => ({values}) );
export const updateMenuLogo = createSaveableAction( MENU_UPDATE_LOGO, (logo) => ({logo}) );
export const updateTheme = createActionGroupUndo( MENU_UPDATE_THEME, (theme) => ({theme}) );

export const MENU_SECTION_ADD = 'MENU_SECTION_ADD';
export const MENU_SECTION_UPDATE_VALUES = 'MENU_SECTION_UPDATE_VALUES';
export const MENU_SECTION_MOVE = 'MENU_SECTION_MOVE';
export const MENU_SECTION_DELETE = 'MENU_SELECTION_DELETE';

export const addSection = createSaveableAction( MENU_SECTION_ADD, (id, values) => ({id,values}) );
export const updateSectionValues = createActionGroupUndo( MENU_SECTION_UPDATE_VALUES, (id, values) => ({id,values}) );
export const moveSection = createSaveableAction( MENU_SECTION_MOVE, (from, to) => ({from,to}) );
export const deleteSection = createSaveableAction( MENU_SECTION_DELETE, (id) => ({id}) );

export const MENU_ITEM_ADD = 'MENU_ITEM_ADD';
export const MENU_ITEM_UPDATE_VALUES = 'MENU_ITEM_UPDATE_VALUES';
export const MENU_ITEM_DELETE = 'MENU_ITEM_DELETE';
export const MENU_ITEM_MOVE = 'MENU_ITEM_MOVE';

export const addMenuItem = createSaveableAction( MENU_ITEM_ADD, (sectionId, values) => ({sectionId, values}) );
export const updateMenuItemValues = createActionGroupUndo( MENU_ITEM_UPDATE_VALUES, (id, values) => ({ id, values}) );
export const deleteMenuItem = createSaveableAction( MENU_ITEM_DELETE, (id) => ({id}) );
export const moveMenuItem = createSaveableAction( MENU_ITEM_MOVE, (id, from, to, destId) => ({id, from, to, destId}) );

// FUTURE: once we implement global options
//export const MENU_ITEM_OPTION_ADD = 'MENU_ITEM_OPTION_ADD';
//export const MENU_ITEM_OPTION_DELETE = 'MENU_ITEM_OPTION_DELETE';

export const MENU_ITEM_OPTION_MOVE = 'MENU_ITEM_OPTION_MOVE';
export const moveMenuItemOption = createSaveableAction( MENU_ITEM_OPTION_MOVE, (id, from, to) => ({id, from, to}));

// Options
export const MENU_OPTION_ADD = 'MENU_OPTION_ADD';
export const MENU_OPTION_UPDATE = 'MENU_OPTION_UPDATE';
export const MENU_OPTION_DELETE = 'MENU_OPTION_DELETE';

export const addOption = createSaveableAction( MENU_OPTION_ADD, (id,values, itemId) => ({id, values, itemId}))
export const updateOption = createActionGroupUndo( MENU_OPTION_UPDATE, (id, values) => ({id, values}) );
export const deleteOption = createSaveableAction( MENU_OPTION_DELETE, (id) => ({id}) );

// Option Values
export const MENU_OPTION_VALUE_UPDATE = 'MENU_OPTION_VALUE_UPDATE';
export const MENU_OPTION_VALUE_MOVE = 'MENU_OPTION_VALUE_MOVE';
export const MENU_OPTION_VALUE_DELETE = 'MENU_OPTION_VALUE_DELETE';

export const updateOptionValue = createActionGroupUndo( MENU_OPTION_VALUE_UPDATE, (id, index, values) => ({id, index, values }) );
export const moveOptionValue = createSaveableAction( MENU_OPTION_VALUE_MOVE, (id, from, to) => ({id, from, to}));
export const deleteOptionValue = createSaveableAction( MENU_OPTION_VALUE_DELETE, (id, index ) => ({id, index }));

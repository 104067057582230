import React, { useState } from 'react';

import { Box, Button, CheckBox, Form, FormField, Header, Heading, Select, Tab, Tabs, Text } from 'grommet';

import { FormEdit } from 'grommet-icons';

import { ChromePicker } from 'react-color';

import { themes } from '@candr-com/menu-common-ui';

import { useDispatch, useSelector } from 'react-redux';

import { updateTheme } from 'actions/menu';

import { selectTheme } from 'selectors/menu';

const THEMES = [
  { label: 'Bistro', value: 'bistro' },
  { label: 'Modern', value: 'modern' },
  { label: 'Simple', value: 'simple' },
];

const ColorBox = ({color = 'white'}) => {

  return (
    <Box width="34px" height="34px" border background={color}/>
  );
};

const ColorField = ({label}) => {

  return (
    <Box direction="row" gap="small" align="center">
      <ColorBox/>
      <Text size="medium" color="text-weak">{label}</Text>
    </Box>
  );
};

const SIZES = [
  { label: 'Smallest', value:'xsmall' },
  { label: 'Small', value: 'small' },
  { label: 'Medium', value: 'medium' },
  { label: 'Large', value: 'large' },
  { label: 'Larger', value: 'xlarge' },
  { label: 'Largest', value: 'xxlarge' },
];

const WEIGHTS = [

  { label: 'Thin', value:'thin' },
  { label: 'Normal', value: 'normal' },
  { label: 'Bold', value: 'bold' },
];

const TextFormatHeader = () => (

  <Box direction="row" gap="small" align="center">
    <Box basis="small">
      &nbsp;
    </Box>
    <Box basis="small" align="center">
      <Text size="small">Size</Text>
    </Box>
    <Box basis="small" align="center">
      <Text size="small">Style</Text>
    </Box>
    <Box basis="xsmall"align="center">
      <Text size="small">Color</Text>
    </Box>
    <Box basis="xsmall"align="center">
      <Text size="small">CAPS</Text>
    </Box>
  </Box>
);


const TextFormat = ({title}) => (

  <Box direction="row" gap="small" align="center">
    <Box basis="small">
      <Text>{title}</Text>
    </Box>
    <Box basis="small" align="start">
      <Select
        options={SIZES}
        labelKey="label"
        valueKey="value"
      />
    </Box>
    <Box basis="small" align="start">
      <Select
        options={WEIGHTS}
        labelKey="label"
        valueKey="value"
      />
    </Box>
    <Box basis="xsmall"align="center">
      <ColorBox/>
    </Box>
    <Box basis="xsmall"align="center">
      <CheckBox/>
    </Box>
  </Box>
);


const Divider = () => (

  <Box border={{side: 'bottom'}} margin={{vertical:'medium'}} height="1px">&nbsp;</Box>
);

const ThemePane = ({onDone}) => {

  const dispatch = useDispatch();

  const theme = useSelector( selectTheme );

  const themeIndex = THEMES.findIndex( ({label}) => label === theme?.meta?.name );

  const values = {

    theme: THEMES[ themeIndex ],
  };

  const updateValues = (updated) => {

    if( values.theme !== updated.theme ) {

      const theme = themes[ updated.theme.value ];

      theme && dispatch( updateTheme( theme ) );
    }
  };

  return (

    <Box gap="medium">
      <Header>
        <Heading level={2} margin="none">Look and Feel</Heading>
        <Button label="Close" onClick={onDone}/>
      </Header>
      <Box>
        <Form
          value={values}
          onChange={updateValues}
        >
          <Tabs alignControls="start">
            <Tab title="General">
              <Box align="start" gap="small" pad={{top: 'medium'}}>
                <FormField name="theme" label="Theme">
                  <Select
                    name="theme"
                    options={THEMES}
                    labelKey="label"
                    valueKey="value"
                  />
                </FormField>
              </Box>
            </Tab>
            <Tab title="Sections">
              <Box gap="small" pad={{top: 'medium'}}>
                <TextFormatHeader/>
                <TextFormat title="Title"/>
                <Divider/>
                <ColorField label="Background"/>
              </Box>
            </Tab>
            <Tab title="Items">
              <Box gap="small" pad={{top: 'medium'}}>
                <TextFormatHeader/>
                <TextFormat title="Name"/>
                <TextFormat title="Description"/>
                <TextFormat title="Price"/>
                <Divider/>
                <ColorField label="Background"/>
              </Box>
            </Tab>
          </Tabs>
        </Form>
      </Box>
    </Box>
  );
};

export default ThemePane;

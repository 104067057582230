import { createSelector } from 'reselect';

const getUndoIndex = state => state.current_menu.index;

const getUndoLimit = state => state.current_menu.limit;

export const selectUndoRedoEnabled = createSelector(
  getUndoIndex,
  getUndoLimit,
  (index,limit) => {

    return ([index > 0, index < limit-1]);
  }
);

import { handleActions } from './handle-actions';

import { PUBLISH_MENU_START, PUBLISH_MENU_FINISH } from 'actions/publish';

import { MENU_LOADED } from 'actions/menu';


const DEFAULT_STATE = {

  publishing: false,
  lastPublished: 0,
};


const reducer = handleActions( {

    [ MENU_LOADED ]: ( draft ) => (DEFAULT_STATE),

    [ PUBLISH_MENU_START ]: ( draft ) => {

      draft.publishing = true;
    },

    [ PUBLISH_MENU_FINISH ]: ( draft, { info, error } ) => {

      draft.publishing = false;
      draft.lastPublished = info?.menuInfo?.lastUpdated || draft.lastPublished;
      draft.error = error;
    },
  },
  DEFAULT_STATE,
);

export default reducer;

import React from 'react';

import { Box, Button, Form, FormField, TextInput } from 'grommet';

import { useDispatch } from 'react-redux';

import { setSectionReorder } from 'actions/menu_edit';
import { updateSectionValues } from 'actions/menu';

const SectionPane = ({section}) => {

  const dispatch = useDispatch();

  const values = { name: section.name || '' };

  const setValues = (updated) => {

    const { name } = updated;

    const updatedValues = { name };

    dispatch( updateSectionValues( section.id, updatedValues ) );
  };

  return (

    <Box gap="small" pad={{top: "medium"}}>
      <Form
        value={values}
        onChange={(nextValue) => setValues( nextValue )}
        validate="blur"
      >
        <FormField name="name" label="Name" required={true}>
          <TextInput name="name"/>
        </FormField>
      </Form>
      <Box direction="row" justify="between" margin={{top:'medium'}}>
        <Button label="Change Order..." onClick={() => dispatch( setSectionReorder( true ) )}/>
      </Box>
    </Box>
  );
};

export default SectionPane;
